<template>
  <div>
    <a href="javascript:void(0)" @click="dialogVisible = true">
      <div class="imgbox">
        <img :src="article.files" alt="" />
      </div>
      <div class="txtbox">
        <h3>{{ article.title }}</h3>
        <p>{{ article.des }}</p>
      </div>
    </a>

    <el-dialog
      title=" "
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div v-html="article.content"></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialogVisible: false,
    }
  },
}
</script>

<style lang="less" scoped>
@import "../style/common.css";
@import "../style/global.css";
@import "../style/news.css";

/deep/.el-dialog {
  background: transparent;
  box-shadow: none;
  .el-dialog__header {
    border-bottom: none;
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
    font-size: 40px;
    position: relative;
    left: 100%;
  }
}
</style>

